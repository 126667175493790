import React from "react";
import PropTypes from "prop-types";
import FluidSizedImg from "./FluidSizedImage";

const FluidSizedLogo = ({ fluidImg, alt }) => (
  <FluidSizedImg fluidImg={fluidImg} alt={alt} className="mx-2 mt-2" />
);

FluidSizedLogo.propTypes = {
  fluidImg: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
};

export default FluidSizedLogo;
