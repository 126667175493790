import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

// Hybrid Gatsby image: is fluid, but keeps original size if fits into screen
const FluidSizedImg = ({ fluidImg, alt, className }) => {
  return (
    <div
      style={{
        width: fluidImg.width,
        maxHeight: fluidImg.height,
      }}
      className={className}
    >
      <GatsbyImage image={fluidImg} alt={alt} />
    </div>
  );
};

FluidSizedImg.propTypes = {
  fluidImg: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default FluidSizedImg;
