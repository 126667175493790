import React from "react";
import FluidSizedLogo from "./FluidSizedLogo";
import { Section } from "./Section";
import { useStaticQuery, graphql } from "gatsby";

const FundedByEUSection = () => {
  const { coFundedByEULogo } = useStaticQuery(graphql`
    query FundedByEUSectionQuery {
      coFundedByEULogo: file(relativePath: { eq: "co-funded-by-eu-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 80, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <Section>
      <p className="text-lg font-bold md:text-xl">Our sponsors:</p>
      <div className="flex flex-wrap justify-center">
        <FluidSizedLogo
          fluidImg={coFundedByEULogo.childImageSharp.gatsbyImageData}
          alt="Funded by EU logo"
        />
      </div>
    </Section>
  );
};

export default FundedByEUSection;

