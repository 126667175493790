import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import HeroSection from "../../components/HeroSection";
import FundedByEUSection from "../../components/FundedByEUSection";
import { Section } from "../../components/Section";
import { Seo } from "../../components/Seo";

// Head function
export const Head = ({ location }) => (
  <Seo pathname={location.pathname} />
);

const ProgramDetailsSection = ({ logoImg }) => (
  <Section>
    <div className="flex flex-col items-center md:items-stretch md:flex-row md:justify-center">
      <div className="flex flex-col justify-center text-center md:text-left md:mr-3 lg:mr-6">
        <p className="text-3xl font-bold uppercase md:mr-0 lg:text-4xl">
          Erasmus+
        </p>
        <p className="text-xl uppercase lg:text-2xl">Cross-European project</p>
      </div>
      <div className="hidden mr-3 border-l-2 border-black border-solid md:block lg:mr-6"></div>
      <div className="flex flex-col justify-center mt-4 text-center md:text-left lg:text-lg md:mt-0">
        <p className="w-64">
          How do we address sustainability and inclusion with young people with
          hearing, visual, and intellectual disabilities?
        </p>
      </div>
    </div>
    <div className="std-mt md:text-lg">
      <div className="flex flex-col gap-4 items-center lg:flex-row">
        <div
          style={{
            minWidth: logoImg.width,
            maxHeight: logoImg.height,
          }}
        >
          <GatsbyImage image={logoImg} alt="Green growth and culture logo" />
        </div>
        <p>
          Youth with disabilities face multiple forms of discrimination which
          leads to their exclusion from society and school. Attitudes toward
          children with disabilities, as well as a lack of resources to
          accommodate them, compound the challenges they face in accessing
          education. While lack of access to school is an issue, an equal
          concern is the inability of the education system to ensure quality
          education for youth with disabilities. In consequence, they suffer
          from low self-esteem and lack the needed skills or knowledge to be
          able to apply for suitable jobs. 
        </p>
      </div>
      <p className="mt-4">
        While the Convention on the Rights of the Child (CRC) and the Education
        For All framework aims to meet the learning needs of all children and
        youth, the Convention on the Rights of Persons with Disabilities (CRPD)
        recalls those obligations. Further, it specifies that “States Parties
        shall take all necessary measures to ensure the full enjoyment by
        children with disabilities of all human rights and fundamental freedoms
        on an equal basis with other children”, and “ensure an inclusive
        education system at all levels and lifelong learning” (articles 7 and
        24). 
      </p>
    </div>
  </Section>
);

ProgramDetailsSection.propTypes = {
  logoImg: PropTypes.object.isRequired,
};

const ProgramPage = ({ data }) => {
  const logoImg = data.logoImg.childImageSharp.gatsbyImageData;
  return (
    <>
      <HeroSection
        bgImg={data.heroBgImg}
        bgImgAltTxt="Hero background people put hands together"
        title="Green growth and culture"
        subtitle="Designing green growth learning aids for young persons with disabilities"
        shaderClassName="opacity-30"
      />
      <main>
        <ProgramDetailsSection logoImg={logoImg} />
        <FundedByEUSection />
      </main>
    </>
  );
};

ProgramPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const programQuery = graphql`
  query GreenGrowthAndCultureQuery {
    heroBgImg: file(relativePath: { eq: "green-growth-and-culture-hero-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    logoImg: file(relativePath: { eq: "green-growth-and-culture-logo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 200, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`;

export default ProgramPage;

